import React from "react"
import PropTypes from "prop-types"
import { createUseStyles } from "react-jss"
import Card from "./card"
import DreamBackground from "./dream-background"

const useStyles = createUseStyles(theme => ({
  wrapper: {
    overflow: "hidden",
    position: "relative",
    backgroundColor: `${theme.colors.core.core} !important`,
    zIndex: 0,
    width: "100%",
    height: "100%",
  },
}))

function DreamCard({ background, spacing, children, className, ...props }) {
  const cx = useStyles()

  return (
    <Card
      styleType="solid"
      padding={spacing}
      className={`${className} ${cx.wrapper}`}
      {...props}
    >
      <DreamBackground background={background} />
      {children}
    </Card>
  )
}

export default DreamCard

DreamCard.defaultProps = {
  background: "blue",
  className: null,
  spacing: "lg",
}

DreamCard.propTypes = {
  background: PropTypes.oneOf(["blue", "pink", "blue-vertical"]),
  spacing: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}
