import React from "react"
import { createUseStyles } from "react-jss"
import PropTypes from "prop-types"

const useStyles = createUseStyles(theme => ({
  backg: {
    zIndex: -1,
    position: "absolute",
  },
  backgreen: {
    width: "100%",
    height: "100%",
    backgroundColor: "#2ea043",
  },
  dot: {
    height: 405,
    width: 405,
    borderRadius: "50%",
    position: "absolute",
    filter: "blur(50px)",
  },
  blueDot: {
    backgroundColor: "#575cfc",
  },
  purpleDot: {
    backgroundColor: "#4b0e75",
  },
  pinkDot: {
    backgroundColor: "#ce12b5",
  },
  greenDot: {
    backgroundColor: "#2ea043",
  },
  yellowDot: {
    backgroundColor: "#FFC31F",
  },
}))

function DreamBackground({ background }) {
  const cx = useStyles()

  if (background === "blue") {
    return (
      <div className={cx.backg}>
        <div
          className={cx.blueDot + " " + cx.dot}
          style={{ transform: "translate(-40%,-0%)", zIndex: 1 }}
        />
        <div
          className={cx.blueDot + " " + cx.dot}
          style={{ transform: "translate(10%,10%)", zIndex: 2 }}
        />
        <div
          className={cx.purpleDot + " " + cx.dot}
          style={{ transform: "translate(-15%,-50%)", zIndex: 2 }}
        />
        <div
          className={cx.purpleDot + " " + cx.dot}
          style={{ transform: "translate(50%,-50%)", zIndex: 1 }}
        />
        <div
          className={cx.purpleDot + " " + cx.dot}
          style={{ transform: "translate(80%,0%)", zIndex: 0 }}
        />
      </div>
    )
  }

  if (background === "pink") {
    return (
      <div className={cx.backg}>
        <div
          className={cx.pinkDot + " " + cx.dot}
          style={{ transform: "translate(-10%,-40%)", zIndex: 2 }}
        />
        <div
          className={cx.pinkDot + " " + cx.dot}
          style={{ transform: "translate(0%,0%)", zIndex: 0 }}
        />
        <div
          className={cx.purpleDot + " " + cx.dot}
          style={{ transform: "translate(-20%,10%)", zIndex: 1 }}
        />
        <div
          className={cx.purpleDot + " " + cx.dot}
          style={{ transform: "translate(54%,27%)", zIndex: 2 }}
        />
        <div
          className={cx.purpleDot + " " + cx.dot}
          style={{ transform: "translate(54%,37%)", zIndex: 2 }}
        />
        <div
          className={cx.purpleDot + " " + cx.dot}
          style={{ transform: "translate(70%,-50%)", zIndex: 1 }}
        />
      </div>
    )
  }

  if (background === "blue-vertical") {
    return (
      <div className={cx.backg}>
        <div
          className={cx.blueDot + " " + cx.dot}
          style={{ transform: "translate(-38%,20%)", zIndex: 2 }}
        />
        <div
          className={cx.blueDot + " " + cx.dot}
          style={{ transform: "translate(28%,40%)", zIndex: 2 }}
        />
        <div
          className={cx.purpleDot + " " + cx.dot}
          style={{ transform: "translate(-50%,-10%)", zIndex: 2 }}
        />
        <div
          className={cx.purpleDot + " " + cx.dot}
          style={{ transform: "translate(-10%,-20%)", zIndex: 1 }}
        />
        <div
          className={cx.purpleDot + " " + cx.dot}
          style={{ transform: "translate(55%,-20%)", zIndex: 1 }}
        />
      </div>
    )
  }

  if (background === "green") {
    return (
      <div className={`${cx.backg} ${cx.backgreen}`}>
        <div
          className={cx.yellowDot + " " + cx.dot}
          style={{ transform: "translate(-38%,20%)", zIndex: 2 }}
        />
        <div
          className={cx.yellowDot + " " + cx.dot}
          style={{ transform: "translate(28%,40%)", zIndex: 2 }}
        />
        <div
          className={cx.greenDot + " " + cx.dot}
          style={{ transform: "translate(-50%,-10%)", zIndex: 2 }}
        />
        <div
          className={cx.greenDot + " " + cx.dot}
          style={{ transform: "translate(-10%,-20%)", zIndex: 1 }}
        />
        <div
          className={cx.yellowDot + " " + cx.dot}
          style={{ transform: "translate(55%,-20%)", zIndex: 1 }}
        />
      </div>
    )
  }
}

export default DreamBackground

DreamBackground.propTypes = {
  // Background props
  background: PropTypes.oneOf(["blue", "pink", "blue-vertical"]).isRequired,
}
