import * as React from "react"
import Section from "../../components/containers/section"
import Main from "../../components/layout/main"
import Seo from "@src/components/seo/seo"
import SectionBackground from "@src/components/containers/section-background"
import SectionContent from "@src/components/containers/section-content"
import Rows from "@src/components/grid/rows"
import Columns from "@src/components/grid/columns"
import Content from "@src/components/grid/content"
import Heading from "@src/components/content/heading"
import Copy from "@src/components/content/copy"
import Footer from "@src/components/grid/footer"
import CtaGroup from "@src/components/containers/cta-group"
import { Link } from "react-scroll"
import Button from "@src/components/interactive/button"
import { StaticImage } from "gatsby-plugin-image"
import Card from "@src/components/containers/card"
import DreamCard from "@src/components/containers/dream-card"

function TermsPage({ location }) {
  const seoObj = {
    title: "Lunio Terms of Service",
    description: "Terms of Service for using the Lunio Solution.",
    url: location && location.pathname,
  }

  return (
    <Main>
      <Seo customData={seoObj} />

      <header>
        <Section>
          <SectionBackground>
            <div className="hero-image--careers" />
          </SectionBackground>
          <SectionContent>
            <Rows gap="lg" className="mt-xxl mb-xl">
              <Columns count="1" alignment="center">
                <Content className="tab-mx-xxl" gap="xl">
                  <Heading alignment="center" level={1}>
                    Our Terms of{" "}
                    <span className="text-gradient text-gradient__purple-blue">
                      Service
                    </span>
                  </Heading>
                  <Copy className="mob-mx-xl">
                    <p className="text--center">
                      Our terms will be different depending on where you're
                      from. Which ones are you looking for?
                    </p>
                  </Copy>
                </Content>
              </Columns>
              <Columns colgap count="2">
                <DreamCard
                  background="pink"
                  styleType="hollow"
                  link="./global/"
                >
                  <Columns count="1,2" alignment="center">
                    <div>
                      <StaticImage
                        src="../../images/terms-of-service/global-terms-globe.png"
                        alt="Global terms globe"
                      />
                    </div>
                    <Content gap="sm">
                      <p className="text--color-white h3">Global (excl. US)</p>
                      <p className="text--color-white">
                        Our terms for those based outside the USA.
                      </p>
                    </Content>
                  </Columns>
                </DreamCard>
                <DreamCard styleType="hollow" link="./usa/">
                  <Columns count="1,2" alignment="center">
                    <div>
                      <StaticImage
                        src="../../images/terms-of-service/usa-terms-liberty.png"
                        alt="Usa terms statue of liberty"
                      />
                    </div>
                    <Content gap="sm">
                      <p className="text--color-white h3">USA</p>
                      <p className="text--color-white">
                        Our terms for those based in the USA.
                      </p>
                    </Content>
                  </Columns>
                </DreamCard>
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>
    </Main>
  )
}

export default TermsPage
